import type { setCookie } from 'nookies';
import type {
  JsonApiParagraphMeta,
  ParagraphMeta,
  TimeControl,
} from '@packages/cms-components/interfaces';

export type SurveyType = JsonApiParagraphMeta &
  ParagraphMeta &
  TimeControl & {
    type: 'surveys--surveys';
    field_display_ratio?: string;
    field_display_second_page?: boolean;
    field_survey_button_link?: string;
    field_survey_button_text?: string;
    field_survey_headline?: string;
    field_survey_text?: {
      value: string;
      format: string;
      processed: string;
    };
    field_tracking_landmark?: string | null;
    field_valid_from?: string;
    field_valid_until?: string;
  };

export type CmsDataSurvey = {
  data: SurveyType;
};

export type SurveyModalType = {
  data: SurveyType;
  isPreview: boolean;
};

export type SurveyHelperType = {
  surveyStorageName: string;
  showOnSecondPage: boolean;
  displayRatio: number;
  isPreview: boolean;
  getCookies: GetCookies;
  setCookie: SetCookie;
};

export type GetCookies = () => { [key: string]: string };

export type SetCookie = typeof setCookie;

export enum SurveyStorage {
  name = 'cms-survey',
  statusSkipped = 'skippedByRatioAt',
  statusClosed = 'closedAt',
  statusDisplayedPage = 'pageCount',
}
