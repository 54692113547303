import type { SurveyHelperType } from '../types';
import { SurveyStorage } from '../types';

/**
 * Determines whether to display the survey modal.
 *
 * @param {string} args.surveyStorageName - The name under which survey data is stored in cookies
 * @param {boolean} [args.showOnSecondPage=false] - Whether to display the modal only after the second page view
 * @param {number} [args.displayRatio=100] - The ratio in percentage for showing the modal (0-100)
 * @param {boolean} args.isPreview - Whether the survey is in preview mode
 * @param {Function} args.getCookies - Function to get cookies
 * @param {Function} args.setCookie - Function to set cookies
 * @returns {boolean} True if the modal should be displayed, otherwise false
 */
export const shouldShowModal = ({
  surveyStorageName,
  showOnSecondPage,
  displayRatio,
  isPreview,
  getCookies,
  setCookie,
}: SurveyHelperType): boolean => {
  const storedData = JSON.parse(getCookies()[surveyStorageName] || '{}');

  // force display if query param is set
  if (isPreview) return true;

  // Survey was closed within the last 30 days or skipped by ratio
  if (storedData[SurveyStorage.statusClosed] || storedData[SurveyStorage.statusSkipped])
    return false;

  // Count potentially displayed page view
  setCookie(
    null,
    surveyStorageName,
    JSON.stringify({
      ...storedData,
      [SurveyStorage.statusDisplayedPage]: (storedData[SurveyStorage.statusDisplayedPage] || 0) + 1,
    }),
    { path: '/' },
  );

  // Skipped by ratio control
  if (displayRatio && Math.random() * 100 > displayRatio) return false;

  // the survey should be displayed only at 2nd page or further
  if (showOnSecondPage && (storedData[SurveyStorage.statusDisplayedPage] || 0) < 1) return false;

  return true;
};
