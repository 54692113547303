import { useEffect, useState } from 'react';
import type { v1 } from '@userlike/messenger';
import { logger, useConfig } from '@packages/utilities';
import { usePathname } from 'next/navigation';
import { useRouterTransition } from '@packages/shared';
import type { ChatBotConfig } from '@packages/config/src/default';
import { shouldDisplayChatBot } from './utils/shouldDisplayChatBot';

/**
 * Initializes and manages the Userlike Messenger (ChatBot) based on route changes.
 * @param {ChatBotConfig} props Configuration for the ChatBot, including paths and visibility.
 */
const ChatBotInit = ({ includePaths = [], excludePaths = [], visibility, key }: ChatBotConfig) => {
  const [apiInstance, setApiInstance] = useState<v1.Api | null>(null);

  /**
   * Custom hook to determine if the ChatBot should be shown based on the current route.
   */
  const { isTransitioning } = useRouterTransition((url) =>
    shouldDisplayChatBot({ path: url, includePaths, excludePaths }),
  );

  /**
   * Checks the current route to decide if ChatBot should be displayed.
   */
  const pathname = usePathname();
  const showChatBot = shouldDisplayChatBot({ path: pathname || '', includePaths, excludePaths });

  /**
   * Asynchronously creates and mounts the ChatBot API.
   */
  const createAndMountApi = async () => {
    const { createMessenger } = await import('@userlike/messenger');

    const result = await createMessenger({
      version: 1,
      widgetKey: key,
    });

    if (result.kind === 'error') throw new Error(result.error);

    const { api } = result.value;
    if (api === null) {
      throw new Error('API reached end-of-life, please check documentation and upgrade.');
    }

    const mountResult = await api.mount();
    if (mountResult.kind === 'error') throw new Error(mountResult.error);

    await api.setVisibility(visibility);

    setApiInstance(api);
  };

  /**
   * Manages ChatBot mounting/unmounting via useEffect.
   * @see createAndMountApi
   */
  useEffect(() => {
    const mountChatBot = async () => {
      if (!apiInstance) {
        try {
          await createAndMountApi();
        } catch (e) {
          logger.error(e, `Failed to mount ChatBot messenger: ${e}`);
        }
      }
    };

    const unmountChatBot = async () => {
      if (apiInstance) {
        try {
          await apiInstance.unmount();
          setApiInstance(null);
        } catch (e) {
          logger.error(e, `Failed to unmount ChatBot messenger: ${e}`);
        }
      }
    };

    if (showChatBot || isTransitioning) {
      mountChatBot();
    } else {
      unmountChatBot();
    }

    /**
     * Cleanup function to unmount the ChatBot when the component is unmounted.
     */
    return () => {
      unmountChatBot();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChatBot, isTransitioning, apiInstance]);
};

/**
 * Wrapper component for `ChatBotInit`. This is a side-effect-only component,
 * meaning it performs some operation (mounting or unmounting the chatbot)
 * without rendering any UI itself. Therefore, it returns `null`.
 *
 * @see https://github.com/userlike/messenger
 * @see https://docs.userlike.com/setup/integration/api
 * @returns {null}
 */
export const ChatBot = (): null => {
  const { chatBot: chatBotConfig } = useConfig();

  if (chatBotConfig.enabled && chatBotConfig.key !== '') {
    ChatBotInit(chatBotConfig);
  }

  return null;
};
