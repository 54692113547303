'use client';

import { useSearchParams } from 'next/navigation';
import useSWR from 'swr';
import { filterTimeControl } from '@packages/shared/src/utils/filterTimeControl';
import { useI18n } from '@packages/shared';
import type { CmsDataSurvey } from './types';
import { SurveyModal } from './Survey.modal';

/**
 * Displays surveys on Pages.
 *
 * @returns {JSX.Element|null} Rendered survey or null if no survey are present
 */
export const Survey = (): JSX.Element | null => {
  const { language, defaultLanguage } = useI18n();
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);
  const { TestDate, previewCurrentSurvey = false } = query;
  const isPreview = Boolean(previewCurrentSurvey) && previewCurrentSurvey !== '';
  const testDate = typeof TestDate === 'string' ? TestDate : undefined;
  const surveyCacheKey = `survey-${language || defaultLanguage}`;
  const { data } = useSWR<CmsDataSurvey>(surveyCacheKey); // data fetched in /apps/shopping/src/pages/_app.tsx
  const hasSurveyData = data && data.data;

  if (hasSurveyData && (filterTimeControl(data.data, testDate) || isPreview)) {
    return <SurveyModal data={data.data} isPreview={isPreview} />;
  }

  return null;
};
